import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxsModule, Select, Store } from '@ngxs/store';
import { ValidateUser } from '../../store/app.actions';
import { GetAdmissionAuthLink } from '../../dashboard/store/admissions-calendar/admissions-calendar.action';
import { AdmissionsCalendarState } from '../../dashboard/store/admissions-calendar/admissions-calendar.state';
import { Observable, ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CreateAdmissionFromAttachment } from '../../dashboard/store/admission-form/admission-form.actions';
import { UserModel } from '../../shared/models';
import { CommonModule } from '@angular/common';

interface DisabledButtonsModel {
  loginToCalendar: boolean;
  close: boolean;
}

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
  standalone: true,
  imports: [NgxsModule, CommonModule],
})
export class MessagePopupComponent implements OnInit, OnDestroy {
  @Input() message: string;
  @Input() closeWithoutRedirect: boolean = false;
  @Input() closeRoute: string[];
  @Input() yellowHeader: boolean;
  @Input() isBtnLoginForAdmission: boolean = false;
  @Input() isBtnClose: boolean = true;
  @Input() closeBtnText: string = '';
  @Input() isEditPrevDcw: boolean = false;
  @Output() closed = new EventEmitter();

  @Select(AdmissionsCalendarState.authLink) authLink$: Observable<string>;
  createAdmissionFromAttachmentPayload$: Observable<any> = this.store.select(
    ({ admissionForm }) => admissionForm?.createAdmissionFromAttachmentPayload,
  );
  countIsLogin = 180;
  isLogin = false;
  disabledButtons: DisabledButtonsModel = {
    loginToCalendar: false,
    close: false,
  };
  private destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (!this.closeBtnText) {
      this.closeBtnText = this.yellowHeader ? 'I understand' : 'Got it!';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.disabledButtons.close = true;
    if (this.closeWithoutRedirect) {
      this.closed.emit();
      this.disabledButtons.close = false;
    } else {
      if (this.closeRoute === null) {
        this.closed.emit({ isLogin: this.isLogin });
        this.disabledButtons.close = false;
      } else {
        this.router.navigate(this.closeRoute || ['']).then(() => {
          this.closed.emit();
          this.disabledButtons.close = false;
        });
      }
    }
  }

  loginTOCalendar(): void {
    this.disabledButtons.loginToCalendar = true;
    this.store
      .dispatch(new GetAdmissionAuthLink())
      .pipe(take(1))
      .subscribe(() => {
        this.authLink$.pipe(takeUntil(this.destroy$)).subscribe((url: string) => {
          if (url) {
            const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000';
            const win: Window = window.open(url, '_blank', params);
            let c = 0;
            const timer = setInterval(() => {
              c += 1;
              if (win?.closed) {
                this.disabledButtons.loginToCalendar = false;
                this.store
                  .dispatch(new ValidateUser())
                  .pipe(take(1))
                  .subscribe(() => {
                    const user: UserModel = this.store.selectSnapshot(({ app }) => app.currentUser);
                    this.isBtnLoginForAdmission = !user.isLoginToCalendar;
                    if (!user.isLoginToCalendar) {
                      this.store
                        .dispatch(new GetAdmissionAuthLink())
                        .pipe(take(1))
                        .subscribe(() => {
                          this.isLogin = user.isLoginToCalendar;
                          this.createAdmissionFromAttachment();
                        });
                    } else {
                      this.isLogin = user.isLoginToCalendar;
                      this.createAdmissionFromAttachment();
                    }
                  });
                clearInterval(timer);
              }
              if (c >= this.countIsLogin) {
                clearInterval(timer);
              }
            }, 1000);
          } else {
            this.disabledButtons.loginToCalendar = false;
          }
        });
      });
  }

  private createAdmissionFromAttachment(): void {
    if (this.isLogin) {
      this.close();
      this.createAdmissionFromAttachmentPayload$.pipe(take(1)).subscribe(payload => {
        if (payload) {
          this.store.dispatch(new CreateAdmissionFromAttachment(payload));
        }
      });
    }
  }
}
